import { Utils } from '@tldraw/core'
import dynamic from 'next/dynamic'
import type { GetServerSideProps } from 'next'
import { getSession } from 'next-auth/react'
import Head from 'next/head'
import router from 'next/router'
import { useEffect } from 'react'

// const Editor = dynamic(() => import('components/Editor'), { ssr: false })

// interface PageProps {
//   isUser: boolean
//   isSponsor: boolean
// }

export default function Home(): JSX.Element {
  useEffect(() => {
    const newBoardId = Utils.uniqueId()
    router.replace(`/${newBoardId}`)
  }, [])

  return (
    <>
      <Head>
        <title>Memory Board</title>
      </Head>
      {/* <Editor id="home" isUser={isUser} isSponsor={isSponsor} /> */}
    </>
  )
}

// export const getServerSideProps: GetServerSideProps = async (context) => {
//   const session = await getSession(context)

//   return {
//     props: {
//       isUser: session?.user ? true : false,
//       isSponsor: session?.isSponsor,
//     },
//   }
// }
